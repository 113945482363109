// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-checkout-donation-confirmed-js": () => import("./../src/pages/checkout/donation-confirmed.js" /* webpackChunkName: "component---src-pages-checkout-donation-confirmed-js" */),
  "component---src-pages-form-success-js": () => import("./../src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-supply-js": () => import("./../src/pages/supply.js" /* webpackChunkName: "component---src-pages-supply-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-volunteer-js": () => import("./../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */)
}

